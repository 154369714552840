import { fold, left } from "fp-ts/Either";
import { identity, pipe } from "fp-ts/function";
import { failure } from "io-ts/PathReporter";
import * as t from "io-ts";
import * as z from "zod";
import { fromError } from "zod-validation-error";
import constant from "lodash/constant";
import { analyticsService } from "./AnalyticsService";

const handleErrors = (errors: t.Errors): never => {
    const formattedErrors = failure(errors);
    const error = new Error("Decode errors: " + JSON.stringify(formattedErrors));
    analyticsService.trackAnalyticsException(error, {}, "session.begin");
    throw error;
};

export const validateAndDecode = <A, O>(codec: t.Type<A, O>, input: unknown): A =>
    pipe(codec.decode(input), fold(handleErrors, identity));

export const parseWithSchema = (zodSchema: z.ZodType, inputs: unknown) => {
    try {
        if (zodSchema instanceof z.ZodString) {
            return zodSchema.parse(inputs);
        } else {
            return z.preprocess(emptyToUndefined, zodSchema).parse(inputs);
        }
    } catch (err) {
        const formattedError = fromError(err);
        console.error(formattedError);
        throw formattedError;
    }
};

// Custom helper codecs
export const nullOr = <A>(codec: t.Type<A>) => t.union([codec, t.nullType]);

export const DateFromStringIO = new t.Type<Date, string, unknown>(
    "DateFromString",
    (input): input is Date => input instanceof Date,
    (input, context) =>
        pipe(
            t.string.decode(input),
            fold(left, string => {
                const date = new Date(Date.parse(string));
                return isNaN(date.getTime()) ? t.failure(string, context) : t.success(date);
            })
        ),
    date => date.toISOString()
);

export const UndefinedIO = new t.Type<undefined, string, unknown>(
    "UndefinedType",
    (input): input is undefined => typeof input === "undefined",
    (input, context) =>
        pipe(
            t.string.decode(input),
            fold(left, string => {
                return string !== "" ? t.failure(string, context) : t.success(undefined);
            })
        ),
    constant("")
);

const emptyToUndefined = (data: any) => (data === "" ? undefined : data);
