import * as t from "io-ts";
import { useBaseQuery } from "../../../../Infrastructure/queryClient/useQuery";
import { validateAndDecode } from "../../../../Infrastructure/Services/ValidationUtil";
import { useQueryClient } from "@tanstack/react-query";

const patientProfileContext = "PatientProfile";

export const patientProfileHearingDevicesBaseUrl = "PatientProfile/HearingDevices?patientId=";
export const useAssignedHearingAidsSelector = (patientId: string | undefined) => {
    return useBaseQuery<HearingAidDto[], HearingAids>(
        {
            url: `${patientProfileHearingDevicesBaseUrl}${patientId}`,
            boundedContext: patientProfileContext,
            notifyOnChangeOn: patientId,
            validate: data => validateAndDecode(t.array(HearingAidIO), data),
        },
        {
            enabled: !!patientId,
            select: data => ({
                left: createHearingAid(getHearingAidBySide(data, "Left")),
                right: createHearingAid(getHearingAidBySide(data, "Right")),
            }),
        }
    );
};

export const getHearingAidBySide = (
    hearingAids: HearingAidDto[] | undefined,
    side: "Left" | "Right"
) => hearingAids?.find(hearingAid => hearingAid.side === side) || null;

export const createHearingAid = (hearingAidDto: HearingAidDto | null): HearingAid | null =>
    hearingAidDto !== null
        ? {
              id: hearingAidDto.deviceId,
              serialNumber: hearingAidDto.serialNumber,
              productInfo: {
                  brand: hearingAidDto.productInfo.brand,
                  productName: hearingAidDto.productInfo.productName,
                  commercialName: hearingAidDto.productInfo.commercialName,
              },
              family: hearingAidDto.family,
              housing: hearingAidDto.housing,
              isDemo: hearingAidDto.isDemo,
          }
        : null;

const queryUrl = (patientId: string) => `PatientProfile/HearingDevices?patientId=${patientId}`;

export const useInvalidateAssignedHearingAids = () => {
    const queryClient = useQueryClient();
    return (patientId: string) => {
        queryClient.setQueryData<undefined>(
            [patientProfileContext, patientId, queryUrl(patientId)],
            () => undefined
        );
    };
};
export interface HearingAids {
    left: HearingAid | null;
    right: HearingAid | null;
}

export interface HearingAid {
    id: string;
    serialNumber: string;
    productInfo: ProductInfo;
    family: number;
    housing: number;
    isDemo: boolean;
}

interface ProductInfo {
    brand: string;
    productName: string;
    commercialName: string;
}

const ProductInfoIO = t.type({
    brand: t.string,
    productName: t.string,
    commercialName: t.string,
});

export const HearingAidIO = t.type({
    deviceId: t.string,
    side: t.keyof({ Left: null, Right: null, "": null }),
    serialNumber: t.string,
    productInfo: ProductInfoIO,
    family: t.number,
    housing: t.number,
    isDemo: t.boolean,
});

export type HearingAidDto = t.TypeOf<typeof HearingAidIO>;
